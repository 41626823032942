import React,{Component} from 'react';
import {GallerySection,Heading,HeadingSpan,CustomRow,CustomCol,
    GalleryImageHolder,GalleryImageHolderBig
} from './gallery.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

class Gallery extends Component {
    
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }

    render() {
        return (
            <GallerySection id="gallerySection">
                <Container>
                    <Heading>
                        {this.props.GalleryData.Heading}
                        <HeadingSpan>{this.props.GalleryData.HeadingSpan}</HeadingSpan>
                    </Heading>

                    <CustomRow>

                        <CustomCol md={4}>
                            <GalleryImageHolderBig onClick={this.openDialogBox.bind(this,this.props.GalleryData,0)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[0].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryImageHolderBig>
                        </CustomCol>

                        <CustomCol md={8}>
                            
                            <CustomRow>
                                <CustomCol md={6}>
                                    <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,1)}>
                                        <GatsbyImage
                                            image={this.props.GalleryData.Slider[1].GallerySliderImg.childImageSharp.gatsbyImageData}
                                            className="GallerySliderImg"
                                            alt="" />
                                    </GalleryImageHolder>
                                </CustomCol>
                                <CustomCol md={6}>
                                    <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,2)}>
                                        <GatsbyImage
                                            image={this.props.GalleryData.Slider[2].GallerySliderImg.childImageSharp.gatsbyImageData}
                                            className="GallerySliderImg"
                                            alt="" />
                                    </GalleryImageHolder>
                                </CustomCol>
                            </CustomRow>

                            <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,3)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[3].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryImageHolder>
                            
                        </CustomCol>
                    </CustomRow>

                    <CustomRow>
                        <CustomCol md={8}>
                            <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,4)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[4].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryImageHolder>
                        </CustomCol>
                        <CustomCol md={4}>
                            <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,5)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[5].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryImageHolder>
                        </CustomCol>

                    </CustomRow>
                </Container>
            </GallerySection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`{
  realestatePage3Json {
    Gallery {
      Heading
      HeadingSpan
      Slider {
        GallerySliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }
}
`}
    render={(data) => (
        <Gallery 
        GalleryData={data.realestatePage3Json.Gallery}
        {...props}
        />
    )}
/>
)
