import {createGlobalStyle} from 'styled-components';
import {device} from './device';
const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;

        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Dosis', sans-serif;
        font-weight: 600;
    }
    p{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color:#fff;
        margin-bottom:20px;
        font-size:14px;
        line-height:1.5;
    }
    a{
        font-size:16px;
        font-family: 'Roboto', sans-serif;
        font-weight:400;
    }
    img{
        margin-bottom:0px;
    }

    h1{
        font-size:60px;
        line-height:1.25;
        margin-bottom:20px;
        text-transform:uppercase;
        color:#fff;

        @media ${device.laptop} {
            font-size:50px;
            line-height:56px;
        }

        @media ${device.mobileXL} {
            font-size:40px;
            line-height:46px;
        }
    }
    h2{
        font-size: 42px;
        line-height:48px;
        margin-bottom: 20px;
        text-transform:uppercase;
        color:#fff;

        @media ${device.tablet} {
            font-size:40px;
            line-height:46px;
        }
    }
    h3{
        font-size: 36px;
        line-height:42px;
        color:#fff;
        margin-bottom: 20px;
        text-transform:uppercase;
    }
    h4{
        font-size:30px;
        line-height:36px;
        color:#fff;
        margin-bottom:20px;
        text-transform:uppercase;

        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:24px;
        line-height:30px;
        color:#fff;
        margin-bottom:20px;
        text-transform:uppercase;
    }
    h6{
        font-size:20px;
        line-height:24px;
        color:#fff;
        margin-bottom:20px;

        @media ${device.mobileXL} {
            font-size:18px;
            line-height:24px;
        }
    }
`;

export default GlobalStyle;