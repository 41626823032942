import React,{Component} from 'react';
import {PropertiesSection,CustomRow,Heading,HeadingSpan,CustomTabList,
    PropertiesOuterWrapper,PropertiesLayout,SpecLayout,SpecIcon,
    SliderWrapper,SliderLayout,ImgButtonLeft,LeftIcon,ImgButtonRight,RightIcon
} from './propertydetails.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage } from "gatsby-plugin-image";

class PropertiesPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            tabIndex: 0
        }
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
        };
        
        return (
            <PropertiesSection id="propertiesSection">
                <Container>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                        <CustomRow>
                        <Col lg={5}>
                            {
                                this.props.PropertiesData.TabList.map((itemTab,idxTab) => {
                                return (
                                    <TabPanel>
                                                <SliderWrapper>
                                                    <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                                                        <LeftIcon />
                                                    </ImgButtonLeft>
                                                    <Slider ref={c => (this.slider = c)} {...settings}>
                                                    {
                                                        itemTab.TabPanel.map((itemPanel,idxPanel) => {
                                                        return (
                                                            <SliderLayout>
                                                                        <GatsbyImage
                                                                            image={itemPanel.Img.childImageSharp.gatsbyImageData}
                                                                            alt=""
                                                                            className="propertyImg" />
                                                                    </SliderLayout>
                                                        );
                                                            })
                                                    }
                                                    </Slider>
                                                    <ImgButtonRight onClick={this.next} aria-label="Next Button">
                                                        <RightIcon />
                                                    </ImgButtonRight>
                                                </SliderWrapper>
                                        </TabPanel>
                                );
                                })
                            }
                            </Col>
                            <Col lg={7}>
                                <Heading>
                                    {this.props.PropertiesData.Heading}
                                    <HeadingSpan>{this.props.PropertiesData.HeadingSpan}</HeadingSpan>
                                </Heading>

                                <CustomTabList>
                                {
                                    this.props.PropertiesData.TabList.map((item,idx) => {
                                        return <Tab>{item.Tab}</Tab>
                                    })
                                }
                                </CustomTabList>

                                {
                                    <PropertiesOuterWrapper>
                                        <PropertiesLayout>
                                        <p>{this.props.PropertiesData.TabList[this.state.tabIndex].Description}</p>
                                            <Row>
                                                <Col md={6} sm={6}>
                                                    <h6>
                                                    {this.props.PropertiesData.TabList[this.state.tabIndex].SpecLayoutHeading}
                                                    </h6>
                                                    {
                                                        this.props.PropertiesData.TabList[this.state.tabIndex].SpecLayout.map((specItem, specIdx)=>{
                                                            return <SpecLayout>
                                                                    <SpecIcon src={specItem.SpecIcon} alt=""/>
                                                                    <p>
                                                                    {specItem.SpecText} 
                                                                    </p>
                                                                </SpecLayout>
                                                        })
                                                    }
                                                </Col>
                                                <Col md={6} sm={6}>
                                                    <h6>
                                                    {this.props.PropertiesData.TabList[this.state.tabIndex].DimensionsHeading}
                                                    </h6>
                                                    {
                                                        this.props.PropertiesData.TabList[this.state.tabIndex].Dimensions.map((dimItem, dimIdx)=>{
                                                            return <SpecLayout>
                                                                    <SpecIcon src={this.props.PropertiesData.TabList[this.state.tabIndex].DimensionIcon} alt=""/>
                                                                    <p>
                                                                    {dimItem.DimText} 
                                                                    </p>
                                                                </SpecLayout>
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                        </PropertiesLayout>
                                    </PropertiesOuterWrapper>
                                }
                            </Col>
                        </CustomRow>
                    </Tabs>
                </Container>
            </PropertiesSection>
        );
    }
}


export default () => (
<StaticQuery
    query={graphql`{
  realestatePage3Json {
    Properties {
      Heading
      HeadingSpan
      TabList {
        Tab
        Description
        TabPanel {
          Img {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        SpecLayoutHeading
        SpecLayout {
          SpecIcon
          SpecText
        }
        DimensionsHeading
        DimensionIcon
        Dimensions {
          DimText
        }
      }
    }
  }
}
`}

    render={(data) => (
        <PropertiesPage PropertiesData={data.realestatePage3Json.Properties}/>
    )}
/>
)