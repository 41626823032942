import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AmenitiesSection,
    HeadingLayout,
    Heading,
    HeadingSpan,
    AmenitiesLayout,
    AmenitiesImage
} from './amenities.style';

import { useStaticQuery, graphql } from 'gatsby';

const Amenities = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
            realestatePage3Json{
                Amenities{
                    Heading
                    HeadingSpan
                    AmenitiesData{
                        Img
                        AmenitiesText
                    }
                }
            }
        }
    `);
    const AmenitiesData = JSONData.realestatePage3Json.Amenities;

    return(
        <AmenitiesSection id="amenitiesSection">
            <Container>
                <Row>
                    <Col md={12}>
                        <HeadingLayout>
                            <Heading>
                            {AmenitiesData.Heading}
                            <HeadingSpan>{AmenitiesData.HeadingSpan}</HeadingSpan>
                            </Heading>
                        </HeadingLayout>
                    </Col>
                </Row>
                <Row>
                    {
                        AmenitiesData.AmenitiesData.map((amnObj, idx) => {
                        return <Col lg={3} md={6} sm={6}>
                                <AmenitiesLayout>
                                    <AmenitiesImage src={amnObj.Img} alt="" />
                                    <h6>
                                    {amnObj.AmenitiesText}
                                    </h6>
                                </AmenitiesLayout>
                            </Col>
                        })
                    }
                </Row>
            </Container>
        </AmenitiesSection>

    );
}

export default Amenities;