import styled from 'styled-components';
import {SectionHeading,Commonbtn} from '../Common/common.style';
import {device} from '../Common/device';
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook';
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter';
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin';
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram';
import {Row} from 'react-bootstrap';

export const FooterSection = styled.section`
`;

export const FooterWrapper = styled.div`
    background:#000;
    padding:100px 0px 30px;

    @media ${device.tablet}{
        padding:80px 10px 10px;
    }
`;


export const Heading = styled(SectionHeading)`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const HeadingSpan = styled.span`
    color:#c5a47e;
`;


export const ContactWrapper = styled.div`
    max-width:800px;
    margin:0px auto;
`;

export const ContactFormLayout = styled.div`
    background-color:#151515;
    padding:40px;
`;

export const FormLayout = styled.div`
    
`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormGroup = styled.div`
    margin-bottom:30px;
`;

export const InputText = styled.input`
    width: 100%;
    border: none;
    background:#000;
    padding: 10px;
    outline:0;
    color:#fff;
    font-size: 16px;
`;

export const ContactSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextareaMessage = styled.textarea`
    width: 100%;
    border: none;
    background:#000;
    padding: 10px;
    height:100px;
    outline:0;
    font-size: 16px;
    color:#fff;
`;

export const TextCenter = styled.div`
    text-align:center;
`;

export const SubmitBtn = styled(Commonbtn)`

    :hover{
        color:#fff;
        text-decoration:none;
    }
`;

export const ContactSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const ContactInfoLayout = styled.div`
    padding:40px 0px;

    @media ${device.laptop}{
        padding:40px 0px 0px;
    }
`;

export const ContactUsLayout = styled.div`
    
`;

export const DetailsLayout = styled.div`
    margin-bottom:30px;
    text-align:center;

    h6{
        text-transform:uppercase;
        margin-bottom:15px;
    }
    p{
        margin-bottom:0px;
        text-align:center;
    }
`;

export const DetailsIcon = styled.img`
    width:50px;
    margin-bottom:20px;
`;



export const BottomFooterWrapper = styled.div`
    background:#151515;
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const BottomFooterPara = styled.p`
    color:#fff;
    margin-bottom:0px;
    padding:15px 0px;   
    text-align:left;
    font-size:15px;

    @media ${device.tablet}{
        text-align:center;
    }
`;

export const BottomLink = styled.a`
    margin-bottom:0px;
    padding:10px 0px;  
    color:#fff;
    text-decoration:none;
    text-align:center;
    font-size:15px;   
    
    :hover{
        color:#fff;
        text-decoration:underline;
    }
`;

export const SocialLayout  = styled.div`
    padding:15px;
    text-align:right;

    @media ${device.tablet}{
        text-align:center;
        padding:0px 15px 15px;
    }
`;

export const FooterSocial = styled.a`
    margin-right:15px;

    @media ${device.tablet}{
        margin:0px 5px;
    }
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #c5a47e;
    background: rgba(197, 164, 126, 0.4);
    border-radius:100%;
    padding: 6px;
    transition:all .5s;

    :hover{
        background:#3b5999;
        color:#fff;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #c5a47e;
    background: rgba(197, 164, 126, 0.4);
    border-radius:100%;
    padding: 6px;
    transition:all .5s;

    :hover{
        background:#00adee;
        color:#fff;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #c5a47e;
    background: rgba(197, 164, 126, 0.4);
    padding: 6px;
    border-radius:100%;
    transition:all .5s;

    :hover{
        background:#3b5999;
        color:#fff;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #c5a47e;
    background: rgba(197, 164, 126, 0.4);
    padding: 6px;
    border-radius:100%;
    transition:all .5s;
    :hover{
        background:#ed008d;
        color:#fff;
    }
`;

