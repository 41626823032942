import React from 'react';
import {AboutSection,AboutWrapper,CustomRow,ImageHolder,TextLayout,HeadingSpan,
    FeaturesLayout,FeaturesTextLayout,FeaturesImg
} from './about.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`{
  realestatePage3Json {
    About {
      HeadingSpan
      Heading
      Description1
      Description2
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      FeaturesImg1
      FeaturesHeading1
      FeaturesDesc1
      FeaturesImg2
      FeaturesHeading2
      FeaturesDesc2
    }
  }
}
`);
    const AboutData = JSONData.realestatePage3Json.About;
    return (
        <AboutSection id="aboutSection">
            
            <Container>
                <AboutWrapper>
                    <CustomRow>
                        <Col lg={6}>
                            <TextLayout>
                                <h2>
                                    <HeadingSpan>
                                        {AboutData.HeadingSpan}
                                    </HeadingSpan>
                                    {AboutData.Heading}
                                </h2>
                                <p>
                                {AboutData.Description1} 
                                </p>
                                <p>
                                {AboutData.Description2} 
                                </p>
                            
                                <Row>
                                    <Col md={6} xs={6}>
                                        <FeaturesLayout>
                                            <FeaturesImg 
                                                src={AboutData.FeaturesImg1} 
                                                alt=""
                                            />
                                            <FeaturesTextLayout>
                                                <h5>
                                                {AboutData.FeaturesHeading1} 
                                                </h5>
                                                <p>
                                                {AboutData.FeaturesDesc1}
                                                </p>
                                            </FeaturesTextLayout>
                                        </FeaturesLayout>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <FeaturesLayout>
                                            <FeaturesImg 
                                                src={AboutData.FeaturesImg2} 
                                                alt=""
                                            />
                                            <FeaturesTextLayout>
                                                <h5>
                                                {AboutData.FeaturesHeading2} 
                                                </h5>
                                                <p>
                                                {AboutData.FeaturesDesc2}
                                                </p>
                                            </FeaturesTextLayout>
                                        </FeaturesLayout>
                                    </Col>
                                </Row>
                            </TextLayout>
                        </Col>
                        <Col lg={6}>
                            <ImageHolder>
                                <GatsbyImage
                                    image={AboutData.Img.childImageSharp.gatsbyImageData}
                                    alt=""
                                    className="aboutImg" />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                    
                </AboutWrapper>
            </Container>
            
        </AboutSection>
    );
}

export default About;