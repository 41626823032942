import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #c5a47e;
    color: #fff;
    padding: 8px 40px;
    text-decoration:none;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#9e7647;
        color: #fff;
        text-decoration:none;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;


export const SectionHeading = styled.h2`
    text-align:center;
    line-height:1;
    text-transform:uppercase;

    @media ${device.tablet} {
        line-height:1;
    }
`;
